.rsvp {
    display: flex;
    justify-content: center;
    margin: auto;
    background-color: #ffffff26;
    width: 66%;
    min-width: 345px;
    backdrop-filter: blur(4px);
    border-radius: 4px;
    padding: 2rem;
}

.guest_confirm_form {
    display: flex;
    flex-direction: column;
    min-width: 80%;
}

.guest_profile {
    display: flex;
    /* background-color: #f7faf7; */
    padding: 2rem;
    /* margin: 0 0 1rem 0; */
    align-items: center;
    /* border-radius: 8px; */
    user-select: none;
    cursor: pointer;
    /* border: 2px solid; */
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.guest_list {
    background-color: #f7faf7;
}

.guest_profile.confirmado {
    /* border-color: #97A75C; */
    /* background-color: #778b5c; */
}

.guest_profile.naoconfirmado {
    border-color: crimson;
    /* background-color: crimson; */
}

button.guest_save {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.name {
    font-size: 2rem;
}


.initial_form {
    display: flex;
    flex-direction: column;
}

.initial_form > p {
    color: #f7faf7de;
    font-size: 18px;
    font-weight: bold;
}

.initial_form > button {
    margin-top: 2rem;
}

.rsvp > * > button {
    border: none;
    background-color: transparent;
    font-family: 'brittany signature';
    font-size: 3rem;
    color: #97A75C;
}

.rsvp > * > p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 18px;
}

.family_not_found {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.family_not_found > button {
    margin-top: 2rem;
    font-size: 3rem;
}

.green {
    color: #97A75C;
}

.guest_list {
    background-color: transparent;
}

.guest_profile {
    color: white;
}

label.checkbox-wrapper {
    padding: 0;
    width: 100%;
    border-radius: 7rem;
}

.saved > h2 {
    text-align: center;
    padding-top: 2rem;
    color: white;
}

/* Checkbox */

.checkbox-input {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
.checkbox-input:checked + .checkbox-tile {
    border-color: #97A75C;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #97A75C;
}
.checkbox-input:checked + .checkbox-tile:before {
    transform: scale(1);
    opacity: 1;
    background-color: #97A75C;
    border-color: #97A75C;
}
.checkbox-input:checked + .checkbox-tile .checkbox-icon, .checkbox-input:checked + .checkbox-tile .checkbox-label {
    color: #97A75C;
}
.checkbox-input:focus + .checkbox-tile {
    border-color: #97A75C;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}
.checkbox-input:focus + .checkbox-tile:before {
    transform: scale(1);
    opacity: 1;
}
.checkbox-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 7rem;
    border-radius: 7rem;
    border: 3px solid #b5bfd9;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.15s ease;
    cursor: pointer;
    position: relative;
}
.checkbox-tile:before {
    content: "";
    position: absolute;
    display: block;
    width: 5rem;
    height: 5rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    left: 0.8rem;
    transition: 0.25s ease;
    background-image: url(data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%27192%27 height=%27192%27 fill=%27%23FFFFFF%27 viewBox=%270 0 256 256%27%3E%3Crect width=%27256%27 height=%27256%27 fill=%27none%27%3E%3C/rect%3E%3Cpolyline points=%27216 72.005 104 184 48 128.005%27 fill=%27none%27 stroke=%27%23FFFFFF%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%2732%27%3E%3C/polyline%3E%3C/svg%3E);
    background-size: 31px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.checkbox-tile:hover {
    border-color: #97A75C;
}
/*.checkbox-tile:hover:before {*/
/*    transform: scale(1);*/
/*    opacity: 1;*/
/*}*/
.checkbox-icon {
    transition: 0.375s ease;
    color: #494949;
}
.checkbox-icon svg {
    width: 3rem;
    height: 3rem;
}
.checkbox-label {
    color: #707070;
    transition: 0.375s ease;
    text-align: center;
    width: 70%;
    padding-left: 25px;
}



/*Loader*/

.loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #fff;
}
.loader:before,
.loader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
}
.loader:after {
    color: #97A75C;
    transform: rotateY(70deg);
    animation-delay: .4s;
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@keyframes rotateccw {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes spin {
    0%,
    100% {
        box-shadow: .2em 0px 0 0px currentcolor;
    }
    12% {
        box-shadow: .2em .2em 0 0 currentcolor;
    }
    25% {
        box-shadow: 0 .2em 0 0px currentcolor;
    }
    37% {
        box-shadow: -.2em .2em 0 0 currentcolor;
    }
    50% {
        box-shadow: -.2em 0 0 0 currentcolor;
    }
    62% {
        box-shadow: -.2em -.2em 0 0 currentcolor;
    }
    75% {
        box-shadow: 0px -.2em 0 0 currentcolor;
    }
    87% {
        box-shadow: .2em -.2em 0 0 currentcolor;
    }
}
